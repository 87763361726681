import React, { Suspense, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { LayoutSplashScreen } from 'src/service/components/LayoutSplashScreen.js';
import { actions, selectors } from 'src/root/store.js';
import { get } from 'lodash';

export const Root = function (props) {
  const {
    currentRouteName, currentPage, dispatch, moduleMaps,
    locales, messages
  } = props;

  // Finds futher-most name path in moduleMaps
  // TODO refactor to saga, ignore set page actions if router is not running
  useEffect(() => {
    if (currentRouteName) {
      const splitPaths = currentRouteName.split('.');
      const routeMapping = splitPaths.reduce((lastFound, pathSegment) => {
        const currentPath = lastFound.constructedPath
          ? lastFound.constructedPath + '.' + pathSegment
          : pathSegment;
        if (currentPath in moduleMaps) {
          lastFound.constructedPath = currentPath;
          lastFound.found = moduleMaps[currentPath];
        }
        return lastFound;
      }, {
        constructedPath: '',
        found: null,
      });
      if (currentPage != routeMapping.found) {
        routeMapping.found
          ? dispatch(actions.setPage(routeMapping.found))
          : dispatch(actions.setPage(moduleMaps.error));
      }
    } else {
      if (currentPage != moduleMaps.notFound) {
        // TODO keep loading screen or display client side error
        //dispatch(actions.setPage(moduleMaps.notFound));
      }
    }
  }, [currentRouteName, currentPage]);


  const CurrentPage = React.lazy(currentPage);

  return (
    (currentPage && messages)
      ? <Suspense fallback={<LayoutSplashScreen />}>
        {
          // TODO IntlProvider seems to not be working when warping Suspense
          // TODO use createIntl and redux store exclusively
        }
        <IntlProvider key={locales.language} locale={locales.language} messages={messages}>
          <CurrentPage />
        </IntlProvider>
      </Suspense>
      : <LayoutSplashScreen />
  );
};

export const RootConnected = connect(
  (state, ownProps) => {
    const mapedProps = {
      currentPage: selectors.currentPage(state),
      currentRouteName: get(selectors.currentRoute(state), 'name', null),
      locales: selectors.locales(state),
      messages: selectors.messages(state),
    };
    return mapedProps;
  }
)(Root);

export default RootConnected;