import { all, call, put, select, delay, take, race } from 'redux-saga/effects';
import produce from 'immer';
import { sagaRunner } from 'src/store.js';
import _, { get, last, first } from 'lodash';
import globals from 'src/init.js';
import { actions as commonActions } from 'src/common/store.js';
import { actions as routerActions } from 'redux-router5';

// Declare all action types
export const actionTypes = {
  RESTORE_CONFIG: 'posHelper/RESTORE_CONFIG',
  UPDATE_CONFIG: 'posHelper/UPDATE_CONFIG',
  GET_CONFIG: 'posHelper/GET_CONFIG',
  START_PRM_WATCH: 'posHelper/START_PRM_WATCH',
  STOP_PRM_WATCH: 'posHelper/STOP_PRM_WATCH',
  SET_AMOUNT: 'posHelper/SET_AMOUNT',
  SET_STATUS: 'posHelper/SET_STATUS',
  SET_TOKEN: 'posHelper/SET_TOKEN',
  TEST_TOKEN: 'posHelper/TEST_TOKEN',
};


// Actions
export const actions = {
  updateConfig: (key, value) => ({
    type: actionTypes.UPDATE_CONFIG,
    payload: { key, value }
  }),
  testToken: () => ({
    type: actionTypes.TEST_TOKEN
  }),
  /*
pay: prmId => ({
  type: actionTypes.PAY_NOW,
  payload: { prmId }
}),
payLater: prmId => ({
  type: actionTypes.PAY_LATER,
  payload: { prmId }
}),
sendMail: (prmId, email) => ({
  type: actionTypes.SEND_MAIL,
  payload: { prmId, email }
}),
cancel: prmId => ({
  type: actionTypes.CANCEL,
  payload: { prmId }
})
*/
};

// Declare selectors
export const selectors = {
  config: state => get(state, 'config.values'),
  token: state => get(state, 'config.values.token') || {},
  tokenValue: state => {
    const authMode = get(state, 'config.values.authMode');
    if (authMode == 'credentials') {
      return get(state, 'config.values.token.value')
    } else if (authMode == 'apiToken') {
      return get(state, 'config.values.apiToken')
    }
  },
  prm: state => get(state, 'currentPrm.prm')
};

// Self service reducer, should be wrapped by immer produce
export const reducer = {
  config: produce((config, action) => {
    const { type, payload } = action;
    if (type == actionTypes.UPDATE_CONFIG) {
      config.values[payload.key] = payload.value;
    } else if (type == actionTypes.RESTORE_CONFIG) {
      config.values = payload;
    } else if (type == actionTypes.SET_TOKEN) {
      config.values.token = {};
      if (payload) {
        const leniencyDelta = 10;
        config.values.token.validUntil = new Date(
          (new Date()).getTime() +
          (payload.expires_in - leniencyDelta) * 1000
        );
        config.values.token.value = payload.access_token;
      }
    }
  }, {}),
  currentPrm: produce((currentPrm, action) => {
    const { type, payload } = action;
    if (type == actionTypes.SET_AMOUNT) {
      currentPrm.prm = payload;
    } else if (type == actionTypes.SET_STATUS) {
      currentPrm.prm.status = payload.status;
    }
  }, {})
};

// Initial state
export const initial = {
  config: {
    values: {
    }
  },
  currentPrm: {
    prm: {}
  }
};


// Sagas
export function* sagas() {
  yield all([
    restoreConfigSaga(),
    navigationWatchStarter(),
    sagaRunner(persistConfigSaga),
    sagaRunner(statusCheckSaga),
    sagaRunner(testTokenSaga),
    sagaRunner(terminalStatusSaga)
  ]);
}

function* restoreConfigSaga() {
  const restoredConfig = yield call(globals.Services.posHelper.getConfig);
  yield put({ type: actionTypes.RESTORE_CONFIG, payload: restoredConfig });
}

function* persistConfigSaga() {
  yield take([actionTypes.UPDATE_CONFIG, actionTypes.SET_TOKEN]);
  const config = yield select(selectors.config);
  yield call(globals.Services.posHelper.putConfig, config)
}

function* statusCheckSaga() {
  const startAction = yield take(actionTypes.START_PRM_WATCH);
  const prmId = startAction.payload;
  yield call(ensureTokenSaga);
  const token = yield select(selectors.tokenValue);
  yield put(commonActions.showModal({
    title: 'Getting amount',
    type: 'spinner',
    message: 'Please wait...'
  }));
  const amountResponse = yield call(globals.Services.posHelper.getAmount, token, prmId);
  yield put({ type: actionTypes.SET_AMOUNT, payload: amountResponse.data });
  yield put(commonActions.hideModal());
  while (true) {
    yield call(ensureTokenSaga);
    const token = yield select(selectors.tokenValue);
    const statusResponse = yield call(globals.Services.posHelper.getStatus, token, prmId);
    yield put({ type: actionTypes.SET_STATUS, payload: statusResponse.data });
    const [delayPassed, stopped] = yield race([
      delay(3000),
      take(actionTypes.STOP_PRM_WATCH)
    ]);
    if (stopped) {
      break;
    }
  }
}

function* terminalStatusSaga() {
  const statusAction = yield take(actionTypes.SET_STATUS);
  const terminalStatuses = {
    payed: true, accepted: true, canceled: false, rejected: false
  };
  const status = statusAction.payload.status;
  if (status in terminalStatuses) {
    if (terminalStatuses[status]) {
      yield put(commonActions.showModal({
        title: `Payment successful`,
        type: 'success',
        message: `Status: ${status}`,
      }));
    } else {
      yield put(commonActions.showModal({
        title: 'Payment failed',
        type: 'warning',
        message: `Status: ${status}`,
      }));
      //yield delay(4 * 1000);
      //yield put(commonActions.hideModal());
    }
    yield put({ type: actionTypes.STOP_PRM_WATCH });
  }
}

function* testTokenSaga() {
  yield take(actionTypes.TEST_TOKEN);
  const config = yield select(selectors.config);
  if (config.username && config.password) {
    yield put(commonActions.showModal({
      title: 'Getting token',
      type: 'spinner',
      message: 'Please wait...'
    }));
    try {
      // clear previous token
      yield put({ type: actionTypes.SET_TOKEN });
      const tokenResponse = yield call(
        globals.Services.posHelper.getToken,
        config.username,
        config.password
      );
      if (tokenResponse.status == 200 && tokenResponse.data) {
        yield put({ type: actionTypes.SET_TOKEN, payload: tokenResponse.data });
        yield put(commonActions.showModal({
          title: `Got New Token`,
          type: 'success',
          message: 'Credentials are correct.',
        }));
      } else {
        yield put(commonActions.showModal({
          title: 'Failed to get token',
          type: 'error',
          message: `Status: ${tokenResponse.status}`
        }));
      }
    } catch (excetion) {
      console.log(excetion);
      yield put(commonActions.showModal({
        title: 'Failed to get token',
        type: 'error',
        message: excetion.message
      }));
    } finally {
      yield delay(4 * 1000);
      yield put(commonActions.hideModal());
    }
  }
}

function* navigationWatchStarter() {
  const navigationAction = yield take(action => get(action, 'payload.route.params.prmId'));
  yield put({ type: actionTypes.START_PRM_WATCH, payload: get(navigationAction, 'payload.route.params.prmId') });
}

function* ensureTokenSaga() {
  const config = yield select(selectors.config);
  const authMode = config.authMode;

  if (authMode == 'credentials') {
    const token = yield select(selectors.token);
    if (token.value) {
      // token expired

      if (!token.validUntil || ((new Date(token.validUntil)).getTime() < (new Date()).getTime())) {
        const tokenResponse = yield call(
          globals.Services.posHelper.getToken,
          config.username,
          config.password
        );
        yield put({ type: actionTypes.SET_TOKEN, payload: tokenResponse.data });
      }
    } else {
      const tokenResponse = yield call(
        globals.Services.posHelper.getToken,
        config.username,
        config.password
      );
      yield put({ type: actionTypes.SET_TOKEN, payload: tokenResponse.data });
    }
  }
}