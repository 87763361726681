export const routeNames = {
  INDEX: 'index',
  CONFIGURE: 'configure',
  SHOW: 'show',
}

// https://router5.js.org/guides/defining-routes
const routes = [
  {
    name: routeNames.INDEX,
    path: '/',
  },
  {
    name: routeNames.CONFIGURE,
    path: '/configure',

  },
  {
    name: routeNames.SHOW,
    path: '/show/:prmId',
  }
];

export const moduleMap = {
  [routeNames.CONFIGURE]: () => import('src/posHelper/Configure.js'),
  [routeNames.INDEX]: () => import('src/posHelper/Index.js'),
  [routeNames.SHOW]: () => import('src/posHelper/Show.js')
};

export default routes;