
// Services
export const getServices = (remoteAdapter, storageAdapter) => {
  return {
    putConfig: (config) => {
      storageAdapter.setItem('config', JSON.stringify(config));
    },
    getConfig: () => {
      try {
        return JSON.parse(storageAdapter.getItem('config')) || {};
      } catch (excetion) {
        console.debug('could not restore config, using empty');
        return {};
      }
    },
    getToken: (username, password) => {
      return remoteAdapter.post(`${AUTH_SERVICE_URL}/auth/token`, {
        username,
        password
      });
    },
    getAmount: (token, prmId) => {
      return remoteAdapter.get('', {
        params: {
          prmId,
          authorization: `Bearer ${token}`
        },
        //headers: {
        //  Authorization: `Bearer ${token}`
        //}
      });
    },
    getStatus: (token, prmId) => {
      return remoteAdapter.get('status', {
        params: {
          prmId,
          authorization: `Bearer ${token}`
        },
        //headers: {
        //  Authorization: `Bearer ${token}`
        //}
      });
    }
  }
};